import React from "react"
import Headline from "./headline"
import * as styles from "./news.module.css"

const NewsItem = ({ date, detail, title }, i) => (
  <li key={i} className={styles.item}>
    <time className={styles.time}>{date}</time>
    <Headline>{title}</Headline>
    <p className={styles.detail}>{detail}</p>
  </li>
)

const News = ({ items }) => (
  <ul className={styles.list}>{items.map(NewsItem)}</ul>
)

export default News
