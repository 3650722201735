import React from "react"
import news from "../data/news.yml"

import SEO from "../components/seo"
import News from "../components/news"

const IndexPage = props => (
  <>
    <SEO title="NEWS" description={news.description} />
    <News items={news.items} />
  </>
)

export default IndexPage
